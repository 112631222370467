import { type Stripe } from "stripe";

import { type Database } from "@fitness-app/supabase";

import { type ClientInvoiceData } from "./Order";
import { type CheckoutSettings } from "./ProductsSettings";

export type ProductEntity = Database["public"]["Tables"]["product"]["Row"];
export type ProductChargeTypeEnum = Database["public"]["Enums"]["ProductChargeType"];
export type PaymentClientEnum = Database["public"]["Enums"]["PaymentClient"];
export type ProductTypeEnum = Database["public"]["Enums"]["ProductType"];
export type ProductStatusEnum = Database["public"]["Enums"]["ProductStatus"];

export type PricePaymentType = "one_time" | "recurring";
export type OrderPaymentType = "one_time" | "recurring" | "free";

export const PaymentClient: Record<
  Database["public"]["Enums"]["PaymentClient"],
  Database["public"]["Enums"]["PaymentClient"]
> = {
  STRIPE: "STRIPE",
  PAY_NOW: "PAY_NOW",
  TPAY: "TPAY",
  PAY_PO: "PAY_PO",
} as const;

export const ProductChargeType: Record<
  Database["public"]["Enums"]["ProductChargeType"],
  Database["public"]["Enums"]["ProductChargeType"]
> = {
  FREE: "FREE",
  PAID: "PAID",
} as const;

export const ProductStatus: Record<
  Database["public"]["Enums"]["ProductStatus"],
  Database["public"]["Enums"]["ProductStatus"]
> = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  ARCHIVED: "ARCHIVED",
} as const;

export const PaymentTypeOption = {
  OneTime: "one_time",
  Recurring: "recurring",
  Free: "free",
} as const;

export const OrderPaymentTypeOption = {
  OneTime: "one_time",
  Recurring: "recurring",
  Free: "free",
} as const;

export const ProductType: Record<
  Database["public"]["Enums"]["ProductType"],
  Database["public"]["Enums"]["ProductType"]
> = {
  PERSONAL_CLIENT_SERVICE: "PERSONAL_CLIENT_SERVICE",
  ADDON: "ADDON",
  AUTOMATED_CLIENT_SERVICE: "AUTOMATED_CLIENT_SERVICE",
  ONLY_CONTENT: "ONLY_CONTENT",
} as const;

export interface UpsellTo {
  productId: string;
  productName: string;
  checkoutId: string | null;
  presaleUrl?: string;
  linkLabel?: string;
}

export enum ProductDurationType {
  Infinite = "infinite",
  UntilDate = "untilDate",
  LastsFor = "lastsFor",
}

export interface UntilDateProductDuration {
  type: ProductDurationType.UntilDate;
  date: string;
}

export interface InfiniteProductDuration {
  type: ProductDurationType.Infinite;
}

export interface LastsForProductDuration {
  type: ProductDurationType.LastsFor;
  count: number;
  unit: "month" | "week";
}

export enum EndAccessStrategy {
  Cancel = "cancel",
  OfferWithTheSamePrice = "offerWithTheSamePrice",
  OfferWithTheCurrentPrices = "offerWithTheCurrentPrices",
}

export interface EndAccessOffers {
  productId: string;
  productName: string;
}

export interface ProductAuthor {
  firstName?: string;
  lastName?: string;
  email?: string;
  avatar?: string | null;
  creator?: string;
}

export interface BaseProduct
  extends Omit<
    ProductEntity,
    "productDuration" | "upsellTo" | "prices" | "customInvoicesClient" | "customAuthor" | "endAccessOffers"
  > {
  id: string;
  name: string;
  description: string;
  images: string[];
  metadata: Record<string, string>;
  status: ProductStatusEnum;
  createdAt: string;
  customAuthor: ProductAuthor | null;
  updatedAt: string;
  chargeType: ProductChargeTypeEnum;
  type: ProductTypeEnum;
  upsellTo?: UpsellTo[];
  endAccessOffers?: EndAccessOffers[];
  productDuration: null | UntilDateProductDuration | InfiniteProductDuration | LastsForProductDuration;
  owner: string;
  slug: string;
}

export enum InvoicesClient {
  Fakturownia = "fakturownia",
  iFirma = "ifirma",
  wFirma = "wfirma",
  inFakt = "inFakt",
}

export type CustomInvoicesClient = {
  accountUrl: string;
  accountName: string;
  client: InvoicesClient;
};

export type ProductSubscriptionInterval = "day" | "month" | "week" | "year";

export type RecurringAccessPeriod = {
  type: "recurring";
  interval_count: number;
  interval: ProductSubscriptionInterval;
};

export type OneTimeAccessPeriod = {
  type: "one_time";
  interval_count: number;
  interval: ProductSubscriptionInterval;
  endAccessStrategy?: EndAccessStrategy;
};

export type PriceAccessPeriod = RecurringAccessPeriod | OneTimeAccessPeriod;

export type Currency = "pln" | "usd" | "eur" | "gbp";

export enum PriceStatus {
  Available = "available",
  Archived = "archived",
}
export interface BaseProductPrice {
  active: boolean;
  currency: Currency;
  livemode: boolean;
  createdAt: number;
  metadata?: Record<string, string>;
  id: string;
  type: PricePaymentType;
  unit_amount: number | null;
  unit_amount_decimal: string | null;
  clients: number;
  status: PriceStatus;
  paymentClient: PaymentClientEnum;
  accessPeriod: PriceAccessPeriod | null;
  customLabel?: string;
  config?: PriceConfig;
  checkoutId?: string | null;
  pendingOrders?: string[];
  hidden?: boolean;
  customInvoiceName?: string;
  installments?: number | null;
  connectedUpsells?: string[] | null;
}

export interface PriceConfig {
  additionalInfo?: string | null;
  quantityLimit?: number | null;
  showAvailable?: boolean;
  showAsPromo?:
    | false
    | {
        priceBeforeDiscount: number;
      };
  activeUntil?: null | number;
}

export interface StripePrice extends BaseProductPrice {
  paymentClient: "STRIPE";
  stripePrice: Stripe.Price;
  paymentsMethods?: {
    blik?: boolean;
    p24?: boolean;
  };
}

export type ProductPrice = StripePrice;

export interface ServiceProduct extends BaseProduct {
  prices: ProductPrice[];
  productChargeType: ProductChargeTypeEnum;
  type: ProductTypeEnum;
  customInvoicesClient?: CustomInvoicesClient | null;
  productDuration: LastsForProductDuration | InfiniteProductDuration | UntilDateProductDuration;
}

export interface AddonProduct extends BaseProduct {
  prices: ProductPrice[];
  productChargeType: typeof ProductChargeType.PAID;
  productType: typeof ProductType.ADDON;
  totalClients: number;
  activeClients: number;
  churnedClients: number;
  totalFreeAccounts: number;
  customInvoicesClient?: CustomInvoicesClient | null;
}

export type RecurringInterval = "day" | "week" | "month" | "year";

export interface RecurringData {
  interval: RecurringInterval;
  interval_count: number;
}

export interface RecurringPrice extends RecurringData {
  currency: Currency;
  amount: number;
  type: typeof PaymentTypeOption.Recurring;
  active: boolean;
  id: string;
  paymentClient: PaymentClientEnum;
  connectedUpsells?: string[] | null;
  installments?: number | null;
}

export interface OneTimePrice {
  currency: Currency;
  amount: number;
  type: typeof PaymentTypeOption.OneTime;
  active: boolean;
  id: string;
  interval?: RecurringInterval;
  interval_count?: number;
  paymentClient: PaymentClientEnum;
  endAccessStrategy: EndAccessStrategy;
  installments?: number | null;
  connectedUpsells?: string[] | null;
}

export interface CreateProductDTO {
  name: string;
  active: boolean;
  chargeType: ProductChargeTypeEnum;
  type: ProductTypeEnum;
  description: string;
  images: string[];
  metadata?: Record<string, string>;
  customInvoicesClient?: CustomInvoicesClient | null;
  upsellTo?: UpsellTo[];
  endAccessOffers?: EndAccessOffers[];
  productDuration: BaseProduct["productDuration"];
  prices: (OneTimePrice | RecurringPrice)[];
  author?: ProductAuthor | null;
}

export interface FreeServiceProduct extends BaseProduct {
  prices: never[];
  chargeType: typeof ProductChargeType.FREE;
  type: typeof ProductType.AUTOMATED_CLIENT_SERVICE;
  customInvoicesClient?: CustomInvoicesClient | null;
  paymentsMethods?: never;
}

export interface ContentProduct extends BaseProduct {
  prices: ProductPrice[];
  customInvoicesClient?: CustomInvoicesClient | null;
  chargeType: ProductChargeTypeEnum;
  type: typeof ProductType.ONLY_CONTENT;
  productDuration: null;
}

export type Product = ServiceProduct | ContentProduct | AddonProduct | FreeServiceProduct;

export interface UpdateProductDTO {
  name: string;
  status: ProductStatusEnum;
  description: string;
  images: string[];
  id: string;
  customInvoicesClient?: CustomInvoicesClient | null;
  upsellTo?: UpsellTo[];
  author?: ProductAuthor;
  endAccessOffers?: EndAccessOffers[];
}

export enum ProductPriceAction {
  Create = "create",
  Remove = "remove",
  Archive = "archive",
  ToggleStatus = "toggle_status",
  ToggleVisibility = "toggle_visibility",
  SetUpsell = "set_upsell",
}

export interface BaseProductPriceDTO {
  action: ProductPriceAction;
  productId: string;
}

export interface CreateProductPriceDTO extends BaseProductPriceDTO {
  action: ProductPriceAction.Create;
  price: OneTimePrice | RecurringPrice;
}

export interface RemoveProductPriceDTO extends BaseProductPriceDTO {
  action: ProductPriceAction.Remove;
  priceId: string;
}

export interface ArchiveProductPriceDTO extends BaseProductPriceDTO {
  action: ProductPriceAction.Archive;
  priceId: string;
}

export interface ToggleProductPriceStatusDTO extends BaseProductPriceDTO {
  action: ProductPriceAction.ToggleStatus;
  priceId: string;
  active: boolean;
}

export interface ToggleProductPriceVisibilityDTO extends BaseProductPriceDTO {
  action: ProductPriceAction.ToggleVisibility;
  priceId: string;
  hidden: boolean;
  checkoutId: string;
}

export interface SetProductPriceUpsellDTO extends BaseProductPriceDTO {
  action: ProductPriceAction.SetUpsell;
  priceId: string;
  connectedUpsells: string[] | null;
}

export type UpdateProductPriceDTO =
  | CreateProductPriceDTO
  | RemoveProductPriceDTO
  | ToggleProductPriceStatusDTO
  | ArchiveProductPriceDTO
  | ToggleProductPriceVisibilityDTO
  | SetProductPriceUpsellDTO;

export interface StandardPriceInfo {
  amount: number;
  currency: Currency;
  active: true;
  id: string;
  accessPeriod: PriceAccessPeriod | null;
  customLabel?: string;
  config?: PriceConfig | null;
  available?: number | true;
  type: "recurring" | "one_time";
  priceId: string;
  allowedPaymentMethods?: ("card" | "blik" | "p24" | "tpay")[];
  connectedUpsells?: string[] | null;
  installments?: number | null;
}

export interface PaidProductInfo {
  active: boolean;
  isFree: false;
  images: string[];
  name: string;
  description: string;
  owner: string;
  author: ProductAuthor | null;
  price: StandardPriceInfo | null;
  prices: StandardPriceInfo[];
  upsellPrices: StandardPriceInfo[];
  cart?: CheckoutSettings["cart"];
  invoiceAsOption: boolean;
  stripeAccountId: string;
  allowUpgradeSubscriptionPlan?: boolean;
  allowDowngradeSubscriptionPlan?: boolean;
  hideCountryField?: boolean;
  allowPromotionCode?: false | "onlyForSubscription" | "forAll";
  showPhoneField?: false | "optional" | "required";
  showAdditionalInfo?: false | "optional" | "required";
  isMetaConversionAvailable: boolean;
}

export interface FreeProductInfo {
  active: boolean;
  isFree: true;
  images: string[];
  name: string;
  description: string;
  owner: string;
  author: ProductAuthor | null;
  price: null;
  cart?: CheckoutSettings["cart"];
  customDomain?: string;
}

export interface RenewalProductInfo {
  strategy: EndAccessStrategy;
  active: boolean;
  isFree: false;
  images: string[];
  name: string;
  description: string;
  owner: string;
  author: ProductAuthor | null;
  cart?: CheckoutSettings["cart"];
  currentPeriodEnd: number;
  client: ClientInvoiceData;
  price: StandardPriceInfo | null;
  prices: StandardPriceInfo[];
  upsellPrices: StandardPriceInfo[];
  invoiceAsOption: boolean;
  stripeAccountId: string;
  hideCountryField?: boolean;
  allowPromotionCode?: false | "onlyForSubscription" | "forAll";
  showPhoneField?: false | "optional" | "required";
  showAdditionalInfo?: false | "optional" | "required";
  isMetaConversionAvailable: boolean;
}

export type ProductInfoResponse = FreeProductInfo | PaidProductInfo | RenewalProductInfo;

export type ProductOrderError = {
  error?: string;
  message: string;
};

export type ProductOrderCreatedResponse =
  | {
      error?: never;
      message?: never;
      paymentIntentId: string;
      successUrl: string;
      cancelUrl: string;
      subscription?: never;
      paymentIntent?: never;
      orderId: string;
      clientSecret: string;
    }
  | {
      error?: never;
      message?: never;
      paymentIntentId: string | null;
      successUrl: string;
      cancelUrl: string;
      subscription: Stripe.Subscription;
      paymentIntent: Stripe.PaymentIntent | null;
      orderId: string;
      clientSecret?: never;
    }
  | {
      error?: never;
      message?: never;
      redirectUrl: string;
      orderId: string;
      paymentClient: typeof PaymentClient.TPAY;
    };
