import React, { memo } from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Disclosure } from "@headlessui/react";
import { Avatar, Button, Checkbox, Tooltip } from "antd";
import { Dumbbell } from "lucide-react";
import { useTranslation } from "react-i18next";

import { type ExerciseRecordingWithExerciseInfo } from "@fitness-app/data-models/entities/ExerciseRecording";
import {
  TrainingStatus,
  type ExerciseInWorkout,
  type SuperSet,
} from "@fitness-app/data-models/entities/TrainingProgram";

import ExerciseInfo from "~/components/ExerciseInfo/ExerciseInfo";
import { ExerciseRecordingBadge } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/ExerciseRecordingBadge";
import ExerciseSet from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/ExerciseSet";
import { ExerciseThumbnail } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/ExerciseThumbnail";
import { createExerciseInfo } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/helpers";
import { RecordVideoSwitch } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/RecordVideoSwitch";
import SuperSetSet from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/SuperSetSet";
import { useProgramBuilderContext } from "~/shared/providers/ProgramBuilderProvider";

interface ExerciseInProgramItemProps {
  exerciseInWorkout: (ExerciseInWorkout & { orderKey: number; isSuperSet?: false }) | SuperSet;
  onExerciseSelect: () => void;
  isSelected: boolean;
  splitSuperSet: (exerciseInWorkout: SuperSet) => void;
  deleteExerciseFromWorkout: (exerciseId: string | string[]) => void;
  toggleRecordingVideoFlag?: (exerciseId: string, checked: boolean) => void;
  addSeriesToExercise: (exerciseId: string) => void;
  removeSeriesFromExercise: (setNumber: number, exerciseId: string) => void;
  changeNumberOfSeries: (numberOfSeries: number, exercisesIds: string[]) => void;
  updateExerciseInWorkout: (exercise: ExerciseInWorkout) => void;
  touched?: boolean;
}

const workoutIconType: Partial<Record<TrainingStatus, { color: string; desc: string; icon: React.ReactNode }>> = {
  [TrainingStatus.REJECTED]: {
    icon: <CloseCircleOutlined style={{ fontSize: 16, color: "#f5222d" }} />,
    color: "#f5222d",
    desc: "Klient zrezygnował z ćwiczenia",
  },
  [TrainingStatus.PARTIALLY_FULFILLED]: {
    icon: <ExclamationCircleOutlined style={{ fontSize: 16, color: "#faad14" }} />,
    color: "#faad14",
    desc: "Ćwiczenie zostało częściowo wykonane",
  },
  [TrainingStatus.FULFILLED]: {
    icon: <CheckCircleOutlined style={{ fontSize: 16, color: "#25b10f" }} />,
    color: "#25b10f",
    desc: "Ćwiczenie zostało w pełni wykonane",
  },
} as const;

const ExerciseInProgramItem = ({
  exerciseInWorkout,
  onExerciseSelect,
  isSelected,
  splitSuperSet,
  deleteExerciseFromWorkout,
  addSeriesToExercise,
  removeSeriesFromExercise,
  changeNumberOfSeries,
  updateExerciseInWorkout,
  toggleRecordingVideoFlag,
}: ExerciseInProgramItemProps) => {
  const { t } = useTranslation("workouts");
  const recordings = useProgramBuilderContext().recordings;

  if (exerciseInWorkout.isSuperSet) {
    const filteredRecordings = exerciseInWorkout.mergedExercises
      .map((merged) => recordings?.[merged.exercise.id])
      .filter((item): item is ExerciseRecordingWithExerciseInfo => !!item);

    return (
      <div className="mb-4 flex-1 cursor-pointer rounded-md border border-gray-200 bg-gray-100 p-2 hover:bg-emerald-100">
        <Disclosure>
          <Disclosure.Button className="flex w-full items-center gap-3 py-2 text-left text-sm">
            <Checkbox checked={isSelected} onClick={(e) => e.stopPropagation()} onChange={() => onExerciseSelect()} />
            <Avatar shape="square" size="large" icon={<Dumbbell className="mt-1.5" />} />

            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-900">
                {t("exerciseInProgram.superset")} ({exerciseInWorkout.mergedExercisesIds.length}) -{" "}
                {createExerciseInfo(exerciseInWorkout.numberOfSeries)} s.
              </span>
              <span className="font-base text-xs text-gray-700">
                {exerciseInWorkout.mergedExercises.map((merged) => merged.exercise.name).join(", ")}
              </span>
            </div>

            <div className="mr-[48px] flex flex-1 justify-end gap-2">
              {filteredRecordings?.length
                ? filteredRecordings?.map((recording) => (
                    <ExerciseRecordingBadge key={recording.id} showName exerciseRecording={recording} />
                  ))
                : null}
              <Button
                type="text"
                size="small"
                icon={<LinkOutlined className="text-amber-500" />}
                onClick={(e) => {
                  e.stopPropagation();
                  splitSuperSet(exerciseInWorkout);
                }}
              />
              <Button
                type="text"
                size="small"
                danger
                icon={<DeleteOutlined className="text-red-500" />}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteExerciseFromWorkout(exerciseInWorkout.mergedExercisesIds);
                }}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <SuperSetSet
              updateExerciseInWorkout={updateExerciseInWorkout}
              data={exerciseInWorkout}
              changeNumberOfSeries={(numberOfSeries) =>
                changeNumberOfSeries(numberOfSeries, exerciseInWorkout.mergedExercisesIds)
              }
              toggleRecordingVideoFlag={toggleRecordingVideoFlag}
            />
          </Disclosure.Panel>
        </Disclosure>
      </div>
    );
  }

  const exerciseRecording = recordings?.[exerciseInWorkout.exercise.id];

  return (
    <div className="mb-4 flex-1 cursor-pointer rounded-md border border-gray-200 bg-gray-100 p-2 hover:bg-emerald-100">
      <Disclosure>
        <Disclosure.Button className="flex w-full items-center gap-3 py-2 text-left text-sm">
          <Checkbox checked={isSelected} onClick={(e) => e.stopPropagation()} onChange={() => onExerciseSelect()} />
          <ExerciseThumbnail exerciseInWorkout={exerciseInWorkout} />

          <span className="text-sm font-medium text-gray-900">{exerciseInWorkout.exercise.name}</span>

          <div className="flex gap-2 text-gray-700">
            <ExerciseInfo exerciseInWorkout={exerciseInWorkout} />
          </div>

          {!exerciseInWorkout.status || exerciseInWorkout.status === TrainingStatus.NEW ? (
            <div className="mr-[48px] flex flex-1 justify-end gap-2">
              {exerciseRecording && <ExerciseRecordingBadge exerciseRecording={exerciseRecording} />}
              <RecordVideoSwitch
                exerciseInWorkoutId={exerciseInWorkout.id}
                toggleRecordingVideoFlag={toggleRecordingVideoFlag}
                defaultValue={exerciseInWorkout.requireExerciseVideoRecording}
              />
              <Button
                type="text"
                size="small"
                icon={<EditOutlined className="text-gray-700" />}
                onClick={(e) => {
                  e.stopPropagation();
                  updateExerciseInWorkout(exerciseInWorkout);
                }}
              />
              <Button
                type="text"
                size="small"
                danger
                icon={<DeleteOutlined className="text-red-500" />}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteExerciseFromWorkout(exerciseInWorkout.id);
                }}
              />
            </div>
          ) : (
            <div className="mr-[48px] flex flex-1 justify-end gap-2">
              {exerciseRecording && <ExerciseRecordingBadge exerciseRecording={exerciseRecording} />}
              <Tooltip title={workoutIconType[exerciseInWorkout.status]?.desc}>
                {workoutIconType[exerciseInWorkout.status]?.icon}
              </Tooltip>
            </div>
          )}
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-500">
          <ExerciseSet
            removeSeriesFromExercise={(setNumber) => removeSeriesFromExercise(setNumber, exerciseInWorkout.id)}
            data={exerciseInWorkout}
            updateExerciseInWorkout={updateExerciseInWorkout}
            addSeriesToExercise={() => addSeriesToExercise(exerciseInWorkout.id)}
          />
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

export default memo(ExerciseInProgramItem);
