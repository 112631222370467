import React, { type FC } from "react";
import { SolutionOutlined, VideoCameraAddOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Badge, Button, message, Popover, Space, Typography } from "antd";
import dayjs from "dayjs";
import { Salad, Utensils } from "lucide-react";
import { type EventProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import {
  clientScheduledTasksActions,
  traineeActivitiesActions,
  traineeMeasurementsActions,
} from "@fitness-app/app-store";
import { ActionType, EVENT_TYPE, type CalendarEvent } from "@fitness-app/data-models/domain/services/ProgramService";
import { TaskType } from "@fitness-app/data-models/entities/Automation";
import { TemplateType } from "@fitness-app/data-models/entities/AutomationTemplate";
import { ExerciseRecordingStatus } from "@fitness-app/data-models/entities/ExerciseRecording";
import {
  ScheduledNutritionType,
  type ProgramAutomationTask,
} from "@fitness-app/data-models/entities/ProgramAutomation";
import { TrainingStatus } from "@fitness-app/data-models/entities/TrainingProgram";

import { SurveyAutomationItem } from "~/modules/Automation/ProgramAutomation/components/AddSurveyTemplateForm/SurveyAutomationItem";
import EmailClientTaskItem from "~/modules/Automation/ProgramAutomation/components/EmailClientTaskItem/EmailClientTaskItem";
import MessageAutomationItem from "~/modules/Automation/ProgramAutomation/components/MessageAutomationItem/MessageAutomationItem";
import {
  mapTrainingStatusToAlert,
  mapTrainingStatusToBadge,
} from "~/modules/Trainee/components/TraineeStatusBadge/mappers";
import CalendarContent from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/CalendarContent";
import MeasurementCalendarEvent from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/MeasurementCalendarEvent";
import NutritionDayTargets from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/Nutrition/NutritionDayTargets";
import WorkoutCalendarItem from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/WorkoutCalendarItem";
import { useAppDispatch } from "~/store/initializeStore";

const CustomEventCell: FC<EventProps<CalendarEvent>> = ({ event }) => {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();

  const removeScheduledProgramTask = async (taskId: string) => {
    void message.loading({ content: "messages.loading.deleting", duration: 0, key: "remove" });
    await dispatch(clientScheduledTasksActions.removeClientTask(taskId));
    message.destroy("remove");
  };

  const setSelectedTask = (task: ProgramAutomationTask) => {
    dispatch(clientScheduledTasksActions.setSelectedTask(task));
  };

  if (event.type === EVENT_TYPE.AUTOMATION_TASK) {
    if (event.data.task.type === ScheduledNutritionType.ByCurrentWeight) {
      return (
        <div className="flex items-center gap-x-2">
          <div>
            <Utensils color="rgb(38, 153, 251)" className="align-text-top" size={16} />
          </div>
          <span>Dodanie zaplanowanej diety</span>
        </div>
      );
    }

    if (event.data.task.type === ScheduledNutritionType.AssignedByTrainer) {
      return (
        <Popover
          title="Dodanie zaplanowanej diety"
          content={
            <Space direction="vertical">
              <Space>
                <Typography.Text type="secondary">Nazwa:</Typography.Text>
                <Typography.Text>{event.data.task.mealsPlanName}</Typography.Text>
              </Space>
            </Space>
          }
        >
          <div className="flex items-center gap-x-2">
            <div>
              <Utensils color="rgb(38, 153, 251)" className="align-text-top" size={16} />
            </div>
            <span>Dodanie diety</span>
          </div>
        </Popover>
      );
    }

    if (event.data.task.type === TemplateType.ScheduledMessages) {
      return (
        <MessageAutomationItem
          item={event.data.task}
          status={event.data.status}
          onRemove={() => removeScheduledProgramTask(event.data.id)}
        />
      );
    }

    if (event.data.task.type === TemplateType.ScheduledSurvey) {
      return (
        <SurveyAutomationItem
          item={event.data.task}
          status={event.data.status}
          onRemove={() => removeScheduledProgramTask(event.data.id)}
          onEdit={() => setSelectedTask(event.data)}
        />
      );
    }

    if (event.data.task.type === TaskType.EmailClient) {
      return (
        <EmailClientTaskItem
          item={event.data.task}
          status={event.data.status}
          onRemove={() => removeScheduledProgramTask(event.data.id)}
        />
      );
    }
  }

  if (event.type === EVENT_TYPE.MEASUREMENT) {
    return <MeasurementCalendarEvent measurement={event.data} />;
  }

  if (event.type === ActionType.StartOfCurrenPlan) {
    return (
      <div className="block overflow-hidden text-ellipsis whitespace-nowrap">
        <SolutionOutlined style={{ color: "#2699fb" }} /> <span>Początek obecnego planu</span>
      </div>
    );
  }

  if (event.type === EVENT_TYPE.MEAL_TRACKED) {
    return (
      <Popover
        placement="left"
        content={
          <NutritionDayTargets
            macrosNeeds={event.data.targets}
            caloriesValue={event.data.calories}
            carbsValue={event.data.macros.carbs}
            fatValue={event.data.macros.fat}
            proteinValue={event.data.macros.protein}
          />
        }
        title={
          <div className="flex items-center justify-between">
            <span>Posiłki</span>
            <Button
              type="link"
              size="small"
              onClick={() => dispatch(traineeMeasurementsActions.selectNutritionDay(event.data))}
            >
              Pokaż szczegóły
            </Button>
          </div>
        }
      >
        <div className="flex items-center gap-x-2">
          <Salad size={16} className="align-text-top" color="#f43f5e" />
          Posiłki
        </div>
      </Popover>
    );
  }

  if (event.type === EVENT_TYPE.WORKOUT) {
    const hasAnyRecordings = !!event.metadata.requiredRecordings?.length;
    const hasAnyRecorded = event.metadata.requiredRecordings?.some((recording) =>
      [ExerciseRecordingStatus.RECORDED, ExerciseRecordingStatus.RATED].includes(recording.status),
    );
    return (
      <Popover
        placement="left"
        content={
          <WorkoutCalendarItem
            workout={event.data}
            type={event.metadata.status ? mapTrainingStatusToAlert[event.metadata.status] : null}
          />
        }
        title={
          <CalendarContent
            buttonText="Pokaż szczegóły"
            handleOnClick={() => {
              dispatch(
                traineeActivitiesActions.setCurrentDayWorkout({
                  workout: event.data,
                  date: dayjs(event.start).format("YYYY-MM-DD"),
                  source: event.metadata.source,
                  id: event.metadata.id ?? null,
                  status: event.metadata.status ?? null,
                  recordings: event.metadata.requiredRecordings ?? null,
                }),
              );
              dispatch(traineeActivitiesActions.setWorkoutEditModalShown());
            }}
          >
            {t(`common:trainingStatus.${event.metadata?.status?.toLowerCase()}`)}
          </CalendarContent>
        }
      >
        <div className="flex justify-between">
          <Badge text={event.title} status={mapTrainingStatusToBadge[event.metadata.status || TrainingStatus.NEW]} />
          {hasAnyRecordings ? (
            hasAnyRecorded ? (
              <VideoCameraOutlined className="text-green-600" />
            ) : (
              <VideoCameraAddOutlined className="text-amber-600" />
            )
          ) : null}
        </div>
      </Popover>
    );
  }
  return null;
};

export default CustomEventCell;
