import React, { useCallback, useEffect, useMemo, useRef, useState, type ComponentType } from "react";
import * as Sentry from "@sentry/react";
import { Button, Card, message, Space, Switch } from "antd";
import dayjs from "dayjs";
import {
  Calendar as BigCalendar,
  dateFnsLocalizer,
  type CalendarProps,
  type DateCellWrapperProps,
  type View,
} from "react-big-calendar";
import withDragAndDrop, { type EventInteractionArgs } from "react-big-calendar/lib/addons/dragAndDrop";
import { useTranslation } from "react-i18next";

import {
  ACTIVITY_EVENTS,
  EVENT_TYPE,
  ProgramService,
  type CalendarEvent,
} from "@fitness-app/data-models/domain/services/ProgramService";
import { type NutritionDaySummary } from "@fitness-app/data-models/entities/ClientNutritionWeek";
import { ProgramAutomationTaskStatus } from "@fitness-app/data-models/entities/ProgramAutomation";
import { getErrorMessage } from "@fitness-app/utils";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import pl from "date-fns/locale/pl";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import timezone from "dayjs/plugin/timezone";

import {
  clientScheduledTasksActions,
  traineeActivitiesActions,
  traineeMeasurementsActions,
} from "@fitness-app/app-store";

import ModalForm from "~/components/ModalForm/ModalForm";
import CustomDateCell from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/CustomDateCell";
import CustomEventCell from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/CustomEventCell";
import EditTraineeWeekSchedule from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/EditTraineeWeekSchedule/EditTraineeWeekSchedule";
import EditTraineeWorkoutDay from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/EditTraineeWorkoutDay/EditTraineeWorkoutDay";
import { useAddingProgramAutomationToClient } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/hooks/useAddingProgramAutomationToClient";
import { type IResource } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/types";
import { useTraineeExerciseRecordingsForProgram } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeVideoRecordings/useTraineeExerciseRecordings";
import AutomatedMessagesFormProvider from "~/shared/providers/AutomatedMessagesFormProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import AutomationScheduleSelectorForm from "./components/AutomationScheduleSelectorForm/AutomationScheduleSelectorForm";
import { NutritionDaySummary as NutritionDaySummaryModal } from "./components/Nutrition/NutritionDaySummary";

dayjs.extend(timezone);

const locales = {
  "pl-PL": pl,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Calendar = withDragAndDrop<CalendarEvent, IResource>(
  BigCalendar as ComponentType<CalendarProps<CalendarEvent, IResource>>,
);

const minTime = new Date();
minTime.setHours(5, 0, 0);
const maxTime = new Date();
maxTime.setHours(23, 0, 0);
const TraineeCalendar = () => {
  const { t } = useTranslation(["trainees", "automation"]);
  const [currentView, setCurrentView] = useState<View>("month");
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [onlyCurrentPlanWorkouts, setOnlyCurrentPlanWorkouts] = useState(false);
  const prevMonth = useRef<number | null>(null);
  const prevYear = useRef<number | null>(null);
  const dispatch = useAppDispatch();
  const { selectedProgram, programDetails } = useAppSelector((state) => state.traineeProgram);
  const { measurementsForDateRange } = useAppSelector((state) => state.traineeMeasurements);
  const { profile } = useAppSelector((state) => state.trainee);
  const clientTasks = useAppSelector((state) => state.clientTasks.list);
  const traineeWorkoutActivities = useAppSelector((state) => state.traineeActivities.activities);
  const { byExerciseParentId } = useTraineeExerciseRecordingsForProgram(profile?.id || "", selectedProgram?.id || "");
  const [nutritionStatistics, setNutritionStatistics] = useState<NutritionDaySummary[]>([]);
  const {
    showAutomationScheduleForm,
    toggleAutomationScheduleForm,
    addingAutomationToClient,
    handleAddingAutomationToClient,
  } = useAddingProgramAutomationToClient(profile?.id || "", currentDate);

  const traineeId = profile?.id;

  const range = useMemo(() => {
    return {
      start: dayjs(currentDate).startOf("month"),
      end: dayjs(currentDate).endOf("month"),
    };
  }, [currentDate]);

  const events = ProgramService.getWorkoutsForDateRange([range.start, range.end], selectedProgram, {
    workouts: programDetails?.workouts || [],
    archivedWorkouts: programDetails?.archivedWorkouts || [],
    clientNotifications: [],
    calendarEvents: [],
    clientActivities: traineeWorkoutActivities,
    workoutsOnlyForCurrentPlan: onlyCurrentPlanWorkouts,
    nutritionStatistics,
    clientTasks,
    measurements: measurementsForDateRange,
    exerciseRecordings: byExerciseParentId,
  });

  useEffect(() => {
    const currentMonth = dayjs(currentDate).get("month");
    const currentYear = dayjs(currentDate).get("year");

    if (prevMonth.current !== currentMonth && traineeId) {
      requestAnimationFrame(() => {
        void dispatch(
          traineeMeasurementsActions.fetchNutritionSummaryForRange({
            dateStart: dayjs(currentDate).subtract(1, "month").startOf("month").toDate(),
            dateEnd: dayjs(currentDate).add(1, "month").endOf("month").toDate(),
            traineeId,
          }),
        )
          .unwrap()
          .then((res) => {
            setNutritionStatistics(res.map((item) => item.data).flat());
          })
          .catch((e) =>
            Sentry.captureException(e, { data: { action: "fetchNutritionSummaryForRange in trainee calendar" } }),
          );
      });

      void dispatch(traineeMeasurementsActions.fetchMeasurementsForRange({ traineeId, selectedDate: currentDate }));
      void dispatch(clientScheduledTasksActions.fetchClientTasks({ traineeId, selectedDate: dayjs(currentDate) }));
      void dispatch(
        traineeActivitiesActions.fetchTraineeActivitiesForRange({ traineeId, selectedDate: dayjs(currentDate) }),
      );
    }

    if (prevYear.current !== currentYear) {
      prevYear.current = currentYear;
    }
    if (prevMonth.current !== currentMonth) {
      prevMonth.current = currentMonth;
    }
  }, [currentDate, traineeId]);

  const onEventSelect = (event: CalendarEvent) => {
    if (event.type === EVENT_TYPE.WORKOUT) {
      dispatch(
        traineeActivitiesActions.setCurrentDayWorkout({
          workout: event.data,
          date: dayjs(event.start).format("YYYY-MM-DD"),
          source: event.metadata.source,
          id: event.metadata.id ?? null,
          status: event.metadata.status ?? null,
          recordings: event.metadata.requiredRecordings ?? null,
        }),
      );
      dispatch(traineeActivitiesActions.setWorkoutEditModalShown());
    }
  };

  const onEventResize = () => {
    void message.warning("Funkcja niedostępna dla tego typu wydarzeń.");
  };

  const onEventDrop = async (data: EventInteractionArgs<CalendarEvent>) => {
    const { event } = data;

    const newDate = data.start;
    if (event?.type === ACTIVITY_EVENTS.WORKOUT) {
      try {
        await dispatch(
          traineeActivitiesActions.dragEventToSpecifiedDay({ newDate: dayjs(newDate).toDate(), event }),
        ).unwrap();
      } catch (e) {
        void message.warning(getErrorMessage(e));
      }
    }

    if (event?.type === EVENT_TYPE.AUTOMATION_TASK) {
      if (event.data.status !== ProgramAutomationTaskStatus.Upcoming) {
        void message.warning("Możesz przesunąć tylko wiadomości o statusie 'Zaplanowany'");
        return;
      }

      if (newDate < new Date()) {
        void message.warning("Nie można przesunąć wysyłki na datę w przeszłości");

        return;
      }

      void dispatch(
        clientScheduledTasksActions.updateClientTask({
          id: event.data.id,
          task: { sendDate: dayjs(newDate).toISOString(), sendAt: dayjs(newDate).unix() },
          isOptimisticUpdate: true,
        }),
      );
    }
  };

  const renderCell = useCallback((props: DateCellWrapperProps) => {
    return <CustomDateCell {...props} setCurrentDate={setCurrentDate} />;
  }, []);

  return (
    <Card
      extra={
        <Space align="center" size={24}>
          <div className="flex items-center gap-x-3">
            <h4>Pokaż ćwiczenia tylko z obecnego planu treningowego</h4>
            <Switch onClick={() => setOnlyCurrentPlanWorkouts((prev) => !prev)} checked={onlyCurrentPlanWorkouts} />
          </div>

          <Button type="primary" onClick={() => toggleAutomationScheduleForm(true)}>
            {t("automation:button.addAutomation")}
          </Button>
        </Space>
      }
    >
      <Calendar
        onView={setCurrentView}
        onNavigate={setCurrentDate}
        onSelectEvent={onEventSelect}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        view={currentView}
        events={events}
        localizer={localizer}
        culture="pl-PL"
        resizable={false}
        // showAllEvents
        resizableAccessor={() => false}
        messages={{
          allDay: "cały dzień",
          next: ">",
          previous: "<",
          today: "dziś",
          month: "miesiąc",
          week: "tydzień",
          day: "dzień",
          agenda: "agenda",
          showMore: (total) => `+${total} więcej...`,
        }}
        min={minTime}
        max={maxTime}
        style={{ height: 850 }}
        components={{
          dateCellWrapper: renderCell,
          event: CustomEventCell,
        }}
      />
      <EditTraineeWeekSchedule currentDate={currentDate} />
      <NutritionDaySummaryModal />
      <EditTraineeWorkoutDay />
      <ModalForm
        title="Dodaj automatyzacje do klienta"
        open={showAutomationScheduleForm}
        onCancel={() => toggleAutomationScheduleForm(false)}
        loading={!!addingAutomationToClient}
      >
        <AutomationScheduleSelectorForm onSubmit={handleAddingAutomationToClient} />
      </ModalForm>
    </Card>
  );
};

const WrappedTraineeCalendar = () => (
  <AutomatedMessagesFormProvider>
    <TraineeCalendar />
  </AutomatedMessagesFormProvider>
);

export default WrappedTraineeCalendar;
