import React, { useState } from "react";
import { YoutubeOutlined } from "@ant-design/icons";
import { Badge, Button, Table, Tag, Tooltip, Typography, type TableProps } from "antd";
import dayjs from "dayjs";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ExerciseRecordingStatus,
  type ExerciseRecordingWithExerciseInfo,
} from "@fitness-app/data-models/entities/ExerciseRecording";

import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";
import { createRecordingStatusOptions } from "~/modules/Trainee/helpers/createStatusOptions";

interface TraineeVideoRecordingsTableProps {
  data: ExerciseRecordingWithExerciseInfo[];
  isLoading: boolean;
  withTraineeInfo?: boolean;
  onAddFeedback?: (recording: ExerciseRecordingWithExerciseInfo) => void;
  statusFilter?: ExerciseRecordingStatus[];
  changeStatusFilter?: (status: ExerciseRecordingStatus[]) => void;
}

export const TraineeVideoRecordingsTable = ({
  data,
  isLoading,
  withTraineeInfo,
  onAddFeedback,
  statusFilter,
  changeStatusFilter,
}: TraineeVideoRecordingsTableProps): React.ReactElement => {
  const { t } = useTranslation(["trainees", "common"]);
  const [videoLink, setVideoLink] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleChange: TableProps<ExerciseRecordingWithExerciseInfo>["onChange"] = (_pagination, filters) => {
    if (changeStatusFilter && !isEqual(statusFilter, filters.status)) {
      changeStatusFilter(filters.status as ExerciseRecordingStatus[]);
    }
  };

  return (
    <>
      <Table<ExerciseRecordingWithExerciseInfo>
        rowKey="id"
        scroll={{ x: true }}
        loading={isLoading}
        dataSource={data}
        onChange={changeStatusFilter ? handleChange : undefined}
        pagination={false}
        locale={{
          emptyText: t("measurements.emptyState"),
        }}
      >
        {withTraineeInfo && (
          <>
            <Table.Column<ExerciseRecordingWithExerciseInfo>
              title={t("tableHeader.name")}
              dataIndex="name"
              key="name"
              render={(_, row) => (
                <Button
                  type="link"
                  className="px-0"
                  onClick={() => navigate(`/trainee/details/${row.traineeId}?tab=measurements&subTab=videos`)}
                >
                  {row.trainee?.firstName || "-"} {row.trainee?.lastName}
                </Button>
              )}
            />
            <Table.Column<ExerciseRecordingWithExerciseInfo>
              title={t("tableHeader.email")}
              dataIndex="email"
              ellipsis
              render={(_, row) => {
                if (row.trainee) {
                  return (
                    <Typography.Link
                      onClick={() => navigate(`/trainee/details/${row.traineeId}?tab=measurements&subTab=videos`)}
                      copyable
                    >
                      {row.trainee.email}
                    </Typography.Link>
                  );
                }
                return "-";
              }}
            />
          </>
        )}

        <Table.Column<ExerciseRecordingWithExerciseInfo>
          key="scheduledAt"
          dataIndex="scheduledAt"
          title={t("measurements.workoutDate")}
          render={(date: string) => dayjs(date).format("DD.MM.YYYY")}
        />

        <Table.Column<ExerciseRecordingWithExerciseInfo>
          key="addedtAt"
          dataIndex="addedAt"
          title={t("measurements.addedAt")}
          render={(_, row) => (row.metadata?.addedAt ? dayjs(row.metadata.addedAt).format("DD.MM.YYYY") : "-")}
        />

        <Table.Column<ExerciseRecordingWithExerciseInfo>
          key="exerciseName"
          dataIndex="exercise"
          title={t("measurements.exerciseName")}
          render={(_, row) => row.exercise.name}
        />

        <Table.Column<ExerciseRecordingWithExerciseInfo>
          key="planName"
          dataIndex="planName"
          title={t("measurements.planName")}
          render={(_, row) => row.plan?.name || "-"}
        />
        <Table.Column<ExerciseRecordingWithExerciseInfo>
          title={t("measurements.link")}
          dataIndex="url"
          key="url"
          render={(url) => {
            if (url) {
              return (
                <YoutubeOutlined
                  className="text-green-500"
                  style={{ fontSize: 22 }}
                  onClick={() => setVideoLink(url)}
                />
              );
            }
            return "-";
          }}
        />
        <Table.Column<ExerciseRecordingWithExerciseInfo>
          title={t("measurements.status")}
          dataIndex="status"
          key="status"
          {...(statusFilter ? { filteredValue: statusFilter, filters: createRecordingStatusOptions(t) } : {})}
          render={(status: ExerciseRecordingWithExerciseInfo["status"]) => {
            if (status === ExerciseRecordingStatus.RECORDED) {
              return (
                <Badge
                  className="whitespace-nowrap"
                  status="processing"
                  text={t(`exerciseRecordingStatus.${status}`)}
                />
              );
            }

            if (status === ExerciseRecordingStatus.RATED) {
              return (
                <Badge className="whitespace-nowrap" status="success" text={t(`exerciseRecordingStatus.${status}`)} />
              );
            }

            return (
              <Badge className="whitespace-nowrap" status="default" text={t(`exerciseRecordingStatus.${status}`)} />
            );
          }}
        />

        {onAddFeedback && (
          <Table.Column<ExerciseRecordingWithExerciseInfo>
            title={t("measurements.feedback")}
            dataIndex="feedback"
            key="feedback"
            render={(feedback: ExerciseRecordingWithExerciseInfo["status"], row) => {
              if (!feedback && row.status === ExerciseRecordingStatus.RECORDED) {
                return (
                  <Button size="small" type="primary" onClick={() => onAddFeedback(row)}>
                    {t("measurements.addFeedback")}
                  </Button>
                );
              }

              if (row.feedback) {
                return (
                  <Tooltip title={row.feedback.content}>
                    <Tag>{dayjs(row.feedback.createdAt).format("DD.MM.YYYY HH:mm")}</Tag>
                  </Tooltip>
                );
              }
              return "-";
            }}
          />
        )}
      </Table>
      <VideoPlayer visible={!!videoLink} videoUrl={videoLink} onClose={() => setVideoLink(null)} />
    </>
  );
};
